import {slugsServices, slugsBlogFeed, slugsMembers, slugsPortFolio} from "../data/applicationData";

export const routes = [
        {
            path:'/',
            component: () => import(/* webpackChunkName: "home" */ '../pages/Home/Home.vue'),
        },
        {
            path:'/home',
            component: () => import(/* webpackChunkName: "home" */ '../pages/Home/Home.vue'),
        },
        {
            path:'/about',
            component: () => import(/* webpackChunkName: "about" */ '../pages/About/About.vue'),
        },
        {
            path:'/services',
            component: () => import(/* webpackChunkName: "services" */ '../pages/Services/Services.vue'),
        },
        {
            path:'/services-details/:id',
            component: () => import(/* webpackChunkName: "servicesdetails" */ '../pages/ServicesDetails/ServicesDetails.vue'),
            meta: {
                sitemap: {
                    // Slugs can also be provided asynchronously
                    // The callback must always return an array
                    //slugs: async () => await getActiveUsers(),
                    slugs: () => slugsServices()
                }
            }
        },
        {
            path:'/pricing',
            component: () => import(/* webpackChunkName: "pricing" */ '../pages/Pricing/Pricing.vue'),
        },
        {
            path:'/portfolio',
            component: () => import(/* webpackChunkName: "portfolio" */ '../pages/Portfolio/Portfolio.vue'),
        },
        {
            path:'/portfolio-details/:id',
            component: () => import(/* webpackChunkName: "portfoliodetails" */ '../pages/PortfolioDetails/PortfolioDetails.vue'),
            meta: {
                sitemap: {
                    slugs: () => slugsPortFolio()
                }
            }
        },
        {
            path:'/team',
            component: () => import(/* webpackChunkName: "team" */ '../pages/Team/Team.vue'),
        },
        {
            path:'/team-details/:id',
            component: () => import(/* webpackChunkName: "teamdetails" */ '../pages/TeamDetails/TeamDetails.vue'),
            meta: {
                sitemap: {
                    // Slugs can also be provided asynchronously
                    // The callback must always return an array
                    //slugs: async () => await getActiveUsers(),
                    //slugs: require('../data/membersdata.json'),
                    slugs: () => slugsMembers()
                    /*slugs: [
                        {
                            id:        1,
                            title:     'hello-world',
                            category:  'infos',
                        },
                        {
                            id:        2,
                            title:     'how-to-fold-socks-faster',
                            category:  'lifehacks',
     
                            priority:  0.9,
                            lastmod:   'February 02, 2020 09:24',
                        },
                        {
                            // Slugs that don't match the regex
                            // pattern of their parameter will
                            // throw an error
                            id:        3,
     
                            title:     'another-post',
                            category:  'misc',
                        }
                    ]      */          
                }
            }
        },
        {
            path:'/faq',
            component: () => import(/* webpackChunkName: "faq" */ '../pages/Faq/Faq.vue'),
        },
        {
            path:'/:pathMatch(.*)*',
            component: () => import(/* webpackChunkName: "errorpage" */ '../pages/404/404.vue'),
        },
        {
            path:'/blog',
            component: () => import(/* webpackChunkName: "blog" */ '../pages/Blog/Blog.vue'),
        },
        {
            path:'/blog-details/:id',
            component: () => import(/* webpackChunkName: "blogdetails" */ '../pages/BlogDetails/BlogDetails.vue'),
            meta: {
                sitemap: {
                    // Slugs can also be provided asynchronously
                    // The callback must always return an array
                    //slugs: async () => await getActiveUsers(),
                    slugs: () => slugsBlogFeed()
                }
            }
        },
        {
            path:'/contact',
            component: () => import(/* webpackChunkName: "contact" */ '../pages/Contact/Contact.vue'),
        }
    ]

