<template>
   <router-view/>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { useHead } from '@vueuse/head'
import siteData from './data/app.config.js';

export default defineComponent({
  name: 'App',
  setup() {
    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      link :[
        { 
          name : "image_src",
          content :"/img/logo/logo.png", 
        },
      ],
      meta: [
        { 
          name : "robots",
          content : "all, noimageindex"
        },
        { 
          name : "Bingbot",
          content : "all, noimageindex"
        },
        { 
          name : "googlebot",
          content : "all, noimageindex"
        },
        { 
          name : "DuckDuckBot",
          content : "all, noimageindex"
        },
        {
          name: `description`,
          content: computed(() => siteData.description),
        },
        {
          name: `keywords`,
          content: computed(() => siteData.keywords),
        },
      ],
    })
  },
})
</script>

