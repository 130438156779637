import Service from './api';

export function getDittaInfo(setData) {
    Service.get("/dittainfo", (status, data) => (setData(data[0])));
}
//PERMISSION DENIED for TABLE Socials
export function getSocials(setData) {
    Service.get("/socials", (status, data) => (setData(data)));
}

export function getAboutDetails(setData){
    Service.get("/about", (status,data) => (setData(data)))
}

export function getSocialsDitta(setData) {
    Service.get("/socials?Where=idmember|", (status, data) => (setData(data)));
}
//Add pagination using offset and limit
export function getBlogFeed( setData) {
    Service.get("/blogfeed?select=*,member:members(idmember,fullname)", (status, data) => (setData(data)));
}

export function getBlogFeedByID(id, setData) {
    Service.get(`/blogfeed?select=*,member:members(idmember,image,rolemember,fullname,datastart)&idblog=eq.${id}`, (status, data) => (setData(data)));
}

export function getBrands(setData) {
    Service.get("/brands", (status, data) => (setData(data)));
}

export function getQualitys(setData) {
    Service.get("/qualitys", (status, data) => (setData(data)));
}

export function getFaqs(setData) {
    Service.get("/faqs", (status, data) => (setData(data)));
}

export function getHeroSlider(setData) {
    Service.get("/herosliders", (status, data) => (setData(data)));
}

export function getServices(setData) {
    Service.get("/services", (status, data) => (setData(data)));
}
export function getServiceDetails(id, setData) {
     Service.get(`/services?idservices=eq.${id}`, (status, data) => (setData(data)));
}

//Join the following tables portfolioscategory, category
export function getPortFolio(setData) {
    Service.get("/portfolios?select=*,categories:portfolioscategory( category:categorys(name:category))", (status, data) =>  {
        data.forEach(portfolio => portfolio.categories = portfolio.categories.map( (category_list) => category_list.category.name ))
        setData(data)
    });
}
export function getPortFolioByID(id, setData) {
    Service.get(`/portfolios?select=*,categories:portfolioscategory( category:categorys(name:category))&idportfolio=eq.${id}`, (status, data) =>  {
        data.forEach(portfolio => portfolio.categories = portfolio.categories.map( (category_list) => category_list.category.name ))
        setData(data)
    });
}

export function getActiveMembers(setData) {
    Service.get("/members?datastart=not.is.null", (status, data) => (setData(data)));
}

export function getInActiveMembers(setData) {
    Service.get("/members?datastart=not.is.null", (status, data) => (setData(data)));
}

export function getActiveMemberById(id, setData) {
    Service.get(`/members?idmember=eq.${id}`, (status, data) => (setData(data)));

}

export function getCounters(setData) {
    Service.get("/counters", (status, data) => (setData(data)));
}

export function getTestimonals(setData) {
    Service.get("/testimonals", (status, data) => (setData(data)));
}

export function getGallery(setData) {
    Service.get("/gallerys", (status, data) => (setData(data)));
}

export function getBlogComments(setData) {
    Service.get("/blogcomments", (status, data) => (setData(data)));
}

export function getBlogCategory(setData) {
    Service.get("/blogcategory", (status, data) => (setData(data)));
}

export function getBlogTags(setData) {
    Service.get("/blogtags", (status, data) => (setData(data)));
}

export async function slugsPortFolio() {
    let ret = [];
    await Service.get("/portfolios", (status, data) => (ret = data));
    let modifiedArr = ret.map(function(element){
        let vret = {};
        vret.id = element.idportfolio;
        // vret.lastmod = element.dateportfolio;
        const low = element.title.toLowerCase();
        vret.title = low.replace('-', " ")
        return vret;
    });
    return modifiedArr;
}

export async function slugsMembers() {
    let ret = [];
    await Service.get("/members", (status, data) => (ret = data));
    let modifiedArr = ret.map(function(element){
        let vret = {};
        vret.id = element.idmember;
        const low = element.fullname.toLowerCase();
        vret.title = low.replace(new RegExp(" ", 'g'), "-")
        return vret;
    });
    return modifiedArr;
}

export async function slugsBlogFeed() {
    let ret = [];
    await Service.get("/blogfeed", (status, data) => (ret = data));

    let modifiedArr = ret.map(function(element){
        let vret = {};
        vret.id = element.idblog;
        // vret.category = element.category;
        vret.lastmod = element.datereference;
        const low = element.title.toLowerCase();
        vret.title = low.replace(new RegExp(" ", 'g'), "-")
        return vret;
    });
    return modifiedArr;
}

export async function slugsServices() {
    let ret = [];
    await Service.get("/services", (status, data) => (ret = data));
    let modifiedArr = ret.map(function(element){
        let vret = {};
        vret.id = element.idservices;
        const low = element.title.toLowerCase();
        vret.title = low.replace(new RegExp(" ", 'g'), "-")
        return vret;
    });
    return modifiedArr;
}