import { createWebHistory,createRouter } from "vue-router";
import { routes } from './routes.js'

const router = createRouter({
    history: createWebHistory(),
    routes:routes
})

router.beforeEach((to, from, next) => {
    next();
    window.scrollTo(0, 0)
});

export default router