import axios from 'axios';

class Service {
  constructor() {
    let service = axios.create({
      headers: {
        'Content-Type': 'application/json',
      },
      });
    this.urlbase =process.env.VUE_APP_URL + process.env.VUE_APP_API_REST;
    
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;
  }

  handleSuccess(response) {
    return response;
  }

  handleError (error) {
    /* eslint-disable */
    if (1==0)
    {
      switch (error.response.status) {
        case 401:
          this.redirectTo(document, '/')
          break;
        case 404:
          this.redirectTo(document, '/404')
          break;
        default:
          this.redirectTo(document, '/500')
          break;
      }
    }
    return Promise.reject(error)
  }

  redirectTo (document, path) {
    document.location = path
  }
  
  get(path, callback) {
    return this.service.get(this.urlbase + path).then((response) => callback(response.status, response.data));
  }

  patch(path, payload, callback) {
    return this.service.request({
      method: 'PATCH',
      url: this.urlbase + path,
      responseType: 'json',
      data: payload
    }).then((response) => callback(response.status, response.data));
  }

  post(path, payload, callback) {
    return this.service.request({
      method: 'POST',
      url: this.urlbase + path,
      responseType: 'json',
      data: payload
    }).then((response) => callback(response.status, response.data));
  }
}

export default new Service;