import { createApp } from 'vue'
import App from './App.vue';
import router from './router';
import VueEasyLightbox from 'vue-easy-lightbox';
import 'vue-easy-lightbox/dist/external-css/vue-easy-lightbox.es5.esm.min.js';
import 'bootstrap/scss/bootstrap.scss';
import 'swiper/css/bundle';
import './assets/css/fontAwesome5Pro.css';
import './assets/fonts/flaticon.css';
import 'bootstrap';
import BootstrapVue3 from "bootstrap-vue-3";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
import i18n from './i18n'
import './assets/scss/main.scss';
import { createHead } from '@vueuse/head'

let app = createApp(App);
const head = createHead();
app.use(router);
app.use(BootstrapVue3);
app.use(VueEasyLightbox);
app.use(i18n);
app.use(head);
app.mount('#app');
